import React from 'react';
import './LecturesChapter.css';
import { TabsType } from '../Chapter';
import { resourceType } from '../ChapterType';

type PropsType = {
  currentTab: TabsType;
  resources: resourceType[];
};

const LecturesChapter: React.FC<PropsType> = (props) => {
  const [selectedResourceName, setSelectedResourceName] = React.useState<string>(props.resources[0]?.name);
  return (
    <div className="LecturesChapter" style={{ display: props.currentTab === TabsType.Lectures ? 'block' : 'none' }}>
      {props.resources.length === 0 && <p className="noResources">لا يوجد مصادر بعد</p>}
      <div className="resources">
        {props.resources.map((resource) => (
          <p onClick={() => setSelectedResourceName(resource.name)} className={'resource' + (resource.name === selectedResourceName ? ' active' : '')} key={resource.name}>
            {resource.name}
          </p>
        ))}
      </div>
      {props.resources.map(
        (resource) =>
          resource.name === selectedResourceName && (
            <div key={resource.name} className="cardLecture">
              <div className="title">
                <p>{resource.name}</p>
                <a href={resource.url} target="_blank" rel="noreferrer">
                  عرض بشاشة واسعة
                </a>
              </div>
              <iframe
                src={resource.url}
                title={resource.name}
                allow="autoplay; fullscreen"
                sandbox="allow-scripts allow-same-origin allow-popups allow-presentation allow-downloads"
              ></iframe>
            </div>
          ),
      )}
    </div>
  );
};
export default LecturesChapter;
